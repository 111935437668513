import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FormEmail from "../components/form-email"
import { Box, Divider, Flex,Heading } from "@chakra-ui/core"

const TellMeMore = () => (
  <Layout>
    <SEO
      title="Tell Me More"
      description="An MVP is a fundamental part of the Lean Startup Methodology. Learn 10 Secrets that will help you build a successful MVP in this free MVP cheat sheet."
    />
    <Box mx="auto" maxWidth={1000} my={0}>
      <Flex direction={{ base: "column", md: "row" }} justify="center">
        <Box
          mt={8}
            mx={4}
          w={{ md: 0.5 }}
          maxW={{ md: 480 }}
          bg="gray.300"
          borderWidth="4px"
          borderColor="white"
          rounded="lg"
        >
          <FormEmail group = '104658583'>
            <Heading size="lg" textAlign="center" color="red.500">
              What is Secret #11!
            </Heading>
            <Heading size="xl" textAlign="center" my={5}>
              How can I create an MVP in 14 Days?
            </Heading>
            <Heading size="lg" textAlign="center" my={6}>
              (Must also be affordable & scalable!)
            </Heading>
          </FormEmail>
        </Box>
      </Flex>

      <Divider borderColor="gray.400" borderWidth="1px" mx={4} />
    </Box>
  </Layout>
)

export default TellMeMore
